import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { WrongRouteComponent } from './components/auth/errors/wrong-route/wrong-route.component';
import { LoginComponent } from './components/auth/login/login.component';
import { HomeComponent } from './components/home/home.component';
import { MessagesComponent } from './components/messages/messages.component';
import { ProjectsModule } from './components/projects/projects.module';
import { ServicesModule } from './components/services/services.module';
import { TeamModule } from './components/team/team.module';
import { TechnologiesModule } from './components/technologies/technologies.module';
import { TestimonialsModule } from './components/testimonials/testimonials.module';
import { AuthGuard } from './guards/auth.guard';
import { DashboardLayoutComponent } from './layouts/dashboard-layout/dashboard-layout.component';
import { Roles } from './models/user';


const routes: Routes = [
  
  // {path:'',component:LoginComponent},
  {path:'auth/login',component:LoginComponent},
  {
    path: '',
    canActivate: [AuthGuard],
    component: DashboardLayoutComponent,
    children: [
      {path:'',component:HomeComponent, data: { title: 'الصفحة الرئيسية' }},
      {path:'home',component:HomeComponent, data: { title: 'الصفحة الرئيسية' }},
    ]
  },
  {
    path: 'app',
    canActivate: [AuthGuard],
    // data: { roles: [Roles.Admin] },
    component: DashboardLayoutComponent,
    children: [
      {path: "projects", loadChildren: "./components/projects/projects.module#ProjectsModule"},
      {path: "services", loadChildren: "./components/services/services.module#ServicesModule"},
      {path: "technologies", loadChildren: "./components/technologies/technologies.module#TechnologiesModule"},
      {path: "team", loadChildren: "./components/team/team.module#TeamModule"},
      {path: "testimonials", loadChildren: "./components/testimonials/testimonials.module#TestimonialsModule"},

      {path:'messages',component:MessagesComponent, data: { title: 'الرسائل الواردة' }},
    ]
  },
  {
    path        : '**',
    pathMatch   : 'full',
    component   : WrongRouteComponent
}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
